const en = {
  global: {
    table: {
      dense: 'dense',
      rows: 'Rows per page',
    },
    messages: {
      success: 'Operation carried out successfully.',
      error: 'An error occurred in the operation, please try again later.',
      action: 'Are you sure to perform this action?',
    },
    buttons: {
      cancel: 'Cancel',
      accept: 'Accept',
      delete: 'Delete',
      save: 'Save',
      understood: 'Ok, got it',
      finish: 'Finish',
      back: 'Back',
      next: 'Next',
      start: 'Start',
    },
    placeholders: {
      search: 'Search',
      name: 'Name(s)',
      lastName: 'Last Name(s)',
      email: 'Email',
      password: 'Password',
    },
    validations: {
      validEmail: 'The email address must be valid',
      validDate:
        'The date is incorrect, please enter the information in the following format: mm/dd/yyyy',
    },
  },
  login: {
    noAccount: `Don't have an account yet? <signup>Sign up</signup>`,
    welcome: 'Welcome back!',
    description: 'Log in to manage your events',
    emoji: '👋🏻',
    loginForm: {
      emailPlaceholder: 'Email',
      passwordPlaceholder: 'Password',
      validEmail: 'Email must be a valid email address',
      requiredEmail: 'Enter your email',
      requiredPassword: 'Enter your password',
      forgotPassword: 'Forgot your password?',
      rememberMe: 'Remember me',
      login: 'Enter',
      noConfirmation: `Haven't received the confirmation email?`,
      resendConfirmation: 'Click <here>here</here> to resend it.',
    },
  },
  signup: {
    hasAccount: 'Already have an account? <login>Log in</login>',
    welcome: 'Welcome!',
    description:
      'Sign up as an event organizer, we want to be part of the experience.',
    emoji: '👋🏻',
    acceptTerms:
      'By registering, you accept the <terms>Terms of service</terms> and the <privacy>Privacy policy</privacy>.',
    signupForm: {
      namePlaceholder: 'Name(s)',
      lastNamePlaceholder: 'Last name(s)',
      emailPlaceholder: 'Email',
      passwordPlaceholder: 'Password',
      validEmail: 'Email must be a valid email address',
      validPassword:
        'Password must be at least 8 characters long, contain a number, a capital letter and a special character',
      requiredEmail: 'Enter your email',
      requiredPassword: 'Enter your password',
      requiredName: 'Enter your name(s)',
      requiredLastName: 'Enter your last name(s)',
      signup: 'Create your account',
      resendConfirmation: {
        title: 'Thanks for signing up!',
        confirmEmail: 'We have sent you a confirmation email.',
        confirmEmailDescription: `Please confirm your email address to confirm your account.`,
        noConfirmation: `Haven't received the confirmation email?`,
        action: 'Click <here>here</here> to resend it.',
        success: 'The confirmation has been resent to your email.',
        error: 'An error occurred, please try again later.',
      },
    },
  },
  resetPassword: {
    title: 'Forgot your password?',
    description:
      'Enter the email address associated with your account to send you a message with the steps to follow to reset your password.',
    emailPlaceholder: 'Email',
    requiredEmail: 'Enter your email',
    validEmail: 'Email must be a valid email address',
    submitButton: 'Change password',
    goBack: 'Go back',
    error: 'There was an error, please try again.',
    success: 'The password change request has been sent successfully!',
    successDescription: 'We have sent a link to your email account.',
    successDescription2:
      'Please check your inbox and follow the instructions to change your password.',
  },
  admin: {
    menu: {
      events: 'My Events',
      billboards: 'Billboards',
      statistics: 'Statistics',
      team: 'My Team',
    },
    pages: {
      events: {
        alerts: {
          notPermission:
            'You have not permission for access. Please verify with administrator',
        },
        table: {
          date: 'Date',
          name: 'Event',
          channelType: 'Event type',
          sales: 'Sales',
          paymets: 'Paymets methods',
          status: 'Status',
        },
        tabs: {
          all: 'All',
          active: 'Active',
          inactive: 'Inactive',
          past: 'Past',
        },
        breadcrumbs: {
          links: [{ name: 'List' }],
          action: 'Create event',
        },
        initialModal: {
          title: 'Events',
          subtitle: 'Create unique events and experiences!',
          description:
            'Manage and control the face-to-face, online or live streaming events that you have created.',
        },
        statistics: ['Ticketed Events', 'All tickets', 'Bookings sold'],
        empty: 'No events',
        searchBy: 'Search event by name...',
      },
      myTeam: {
        initialModal: {
          title: 'My Team',
          subtitle: 'Manage your team and optimize your operation',
          description:
            'Add members to your team and manage their roles and permissions so they have access to certain parts of your event administration.',
        },
        breadcrumbs: {
          heading: 'My Team',
          action: 'Add member',
        },
        emptyState: { title: 'There are no members in your team yet' },
        addForm: {
          success: 'Colaborador agregado exitosamente',
          error: 'Ocurrió un error al agregar al colaborador',
        },
      },
      billboards: {
        actions: {
          delete: {
            action: 'Do you want to delete this Bilboard?',
            success: 'Bilboard erased successfully',
            error: 'An error occurred while deleting this Bilboard',
          },
          changeStatus: {
            activate: 'Are you sure to activate the billboard?',
            disabled: 'Are you sure to disabled the billboard?',
            success: 'Billboard status has been changed',
            error: 'There was a problem updating this billboard',
          },
        },
      },
      statistics: {
        filters: {
          byEvent: 'By event ID',
          byUser: 'By user ID',
        },
        breadcrumbsInsiders: {
          insiders: 'Insiders',
          byInsiderTitle: 'Filter by insider',
          placeholder: "User ID, names or email",
          search:'Search',
          insiderName: "Insider's name",
          salesChannel: 'Sales Channel',
          clear: 'Clear',
          filter: 'Filter'
        },
        serviceNotAvailable: 'The statistics service is taking too long to respond. Please try again later.',
      },
      surveys: {
        actions: {
          submit: {
            warning:
              'You need to add options in the question type select from a list',
          },
        },
      },
    },
  },
  event: {
    holds: {
      actions: {
        delete: {
          action: 'Do you want to delete this hold?',
          success: 'Hold erased successfully',
          error: 'An error occurred while deleting this hold',
        },
      },
    },
    batches: {
      actions: {
        addBatch: {
          success: 'Batch created successfully',
          error: 'An error occurred while creating the courtesy',
        },
      },
    },
  },
  createEvent: {
    title: 'Create event',
    subtitle: 'Create a unique event and experience for your attendees',
    description:
      'Create your face-to-face, online or live streaming events from here.',
    placeholders: {
      name: 'Event name',
      nameSublabel:
        'If it is your first edition use a short and recognizable name',
      subdomain: 'Sales site',
      subdomainSublabel: 'This is the URL of your event',
      onsite: 'Onsite',
      neerme: 'Neerme',
      digital: 'Digital (others)',
      description: 'Description',
    },
    successModal: {
      title: 'The event has been created successfully',
      subtitle:
        'Your event has been created. You can continue configuring it or go to the list of your events.',
      goBack: 'Go back to my events',
      goConfig: 'Continue to configuration',
    },
    errorModal: {
      title: 'There was a problem creating the event',
      subtitle: 'You can go back to edit the details to correct the error',
      confirmation: 'Edit',
    },
    schema: {
      subdomainUnavailable: 'This URL is not available',
      requiredSubdomain: 'You must select a sales site',
      minDuration: 'The event must have a minimum duration of 15 minutes',
      requiredName: 'The event name is required',
      requiredChannelType: 'You must select an event type',
      requiredAbout: 'Enter a description for the event',
      requiredStartDate: 'The date must be current',
      requiredDateFormat:
        'The date and time are incorrect, please enter the information with the following format: mm/dd/yyyy hh:hh (a|p)m',
      requiredEndDate: 'The date must be after the start date',
      requiredTimeZone: 'The time zone is required',
    },
  },
  superAdmin: {
    changeProfile: 'Change profile',
    noResults: 'No results',
    searching: 'Searching...',
    nameOrId: 'event name or ID',
    emailOrId: `user email or user ID`,
    exit: 'Exit',
    managedBy: 'You are currently managing the <bold>{{user}}</bold> profile.',
    byEvent: 'By Event',
    byUser: 'By User',
  },
  onboarding: {
    modalButtons: {
      next: 'Next',
      back: 'Back',
      finish: 'Finish',
    },
  },
  checkinAlert: {
    title: 'Check-in!',
    description:
      'Manage your registrations from admin in the <app>event control</app> section and from the Check-in app control access to your events. Available for <android>Android</android> and <ios>iOS</ios>.',
  },
  errors: {
    undefined: 'Service unavailable, please try again later.',
    REQUIRED_FIELD: 'This field is required',
    EVENT_PROMOTION_SERVICE_ERROR:
      'Code already exist, please assign a different code',
    BILLBOARDS_BILLBOARD_ID_URL_PARAMS:
      'error getting billboard id in url params',
    BILLBOARDS_BODY_VALIDATION: 'object not received to do the action',
    BILLBOARDS_QUERY_PROCESS: 'query error',
    BILLBOARDS_REQUEST_VALIDATION: 'validation error',
    BILLBOARDS_SELECT_AT_LEAST_ONE_EVENT: 'you must select at least one event',
    BILLBOARDS_STATEMENT_PROCESS: 'statement error',
    BILLBOARDS_STATUS_URL_PARAMS: 'error getting status in url params',
    BILLBOARDS_SUBDOMAIN_EXISTS: 'subdomain already exists',
    BILLBOARDS_SUBDOMAIN_URL_PARAMS: 'error getting subdomain in url params',
    BILLBOARDS_UPLOADING_IMAGE: 'an error occurred while uploading the image',
    BILLBOARDS_USER_ID_HEADER: 'error getting user id in header',
    BOOKINGS_BODY_VALIDATION: 'object not received to do the action',
    BOOKINGS_EVENT_DATA_NIL: 'there is no information',
    BOOKINGS_EVENT_ID_TICKET_TYPES_NIL: 'this event does not have ticket types',
    BOOKINGS_HOLD_AND_BATCH_VALIDATION:
      'different quantity between hold and batch',
    BOOKINGS_QUANTITY_ERROR: 'quantity must not be greater than 250',
    BOOKINGS_QUANTITY_VALIDATION: 'quantity must be greater than zero',
    BOOKINGS_QUERY_PROCESS: 'query error',
    BOOKINGS_REQUEST_VALIDATION: 'validation error',
    BOOKINGS_RESERVATION_WAS_NOT_CREATED: 'reservation was not created',
    BOOKINGS_STATEMENT_PROCESS: 'statement error',
    BOOKINGS_TICKET_TYPES_QUANTITY:
      'repository-consumer types quantity without enough capacity',
    BOOKINGS_TICKET_TYPE_DOES_NOT_EXIST: 'this ticket type does not exist',
    COMMISSIONS_COMMISSIONS_ALREADY_CREATED_FOR_EVENT:
      'commissions already created for this event',
    COMMISSIONS_COMMISSIONS_ALREADY_CREATED_FOR_USER:
      'commissions already created for this user',
    COMMISSIONS_COMMISSION_BY_DEFAULT: 'do not exist commissions by default',
    COMMISSIONS_COMMISSION_WAS_NOT_CREATED: 'commission was not created',
    COMMISSIONS_EVENT_ID_URL_PARAMS: 'error getting event id in url params',
    COMMISSIONS_REQUEST_VALIDATION: 'validation error',
    COMMISSIONS_STATEMENT_PROCESS: 'statement error',
    COMMISSIONS_USER_ID_URL_PARAMS: 'error getting user id in url params',
    EVENTS_ABOUT_VALIDATION: 'about can not be null or string empty',
    EVENTS_ADDRESS_VALIDATION:
      'address location can not be string empty or null',
    EVENTS_BODY_EVENT_IDS: 'error getting event ids in body',
    EVENTS_BODY_VALIDATION: 'object not received to do the action',
    EVENTS_BOOKINGS_COMPLETE_CAN_NOT_ASSIGN_MAP:
      'this event already has complete bookings you can not assign map',
    EVENTS_BOOKINGS_COMPLETE_CAN_NOT_MODIFY_FEE_BREAKDOWN:
      'this event already has complete bookings you can not modify the fee breakdown',
    EVENTS_BOOKINGS_COMPLETE_CAN_NOT_UNASSIGN_MAP:
      'this event already has complete bookings you can not unassign map',
    EVENTS_CHANNEL_TYPE_VALIDATION:
      'channel type cannot be different from digital or onsite',
    EVENTS_COLLABORATOR_ID_URL_PARAMS:
      'error getting collaborator id in url params',
    EVENTS_COPING_IMAGE: 'an error occurred while coping the image',
    EVENTS_DELETING_IMAGE: 'an error occurred while deleting the image',
    EVENTS_EVENT_DOES_NOT_EXIST: 'this event does not exist',
    EVENTS_EVENT_DOES_NOT_HAVE_PAYMENT_METHODS_ACTIVE:
      'this event does not have payment methods active',
    EVENTS_EVENT_DOES_NOT_HAVE_TICKET_TYPES_ACTIVE:
      'this event does not have ticket types active',
    EVENTS_EVENT_ENDED: 'this event ended cannot be changed to active',
    EVENTS_EVENT_ID_URL_PARAMS: 'error getting event id in url params',
    EVENTS_FILTER_QUERY_PARAMS: 'error getting filter in query',
    EVENTS_HAS_COMPLETE_BOOKING_CHANNEL_TYPE:
      'this event already has complete bookings you can not modify channel type',
    EVENTS_HAS_COMPLETE_BOOKING_CURRENCY:
      'this event already has complete bookings you can not modify currency',
    EVENTS_HAS_COMPLETE_BOOKING_EVENT_TYPE:
      'this event already has complete bookings you can not modify event type',
    EVENTS_HAS_COMPLETE_BOOKING_SUBCATEGORY:
      'this event already has complete bookings you can not modify subcategory',
    EVENTS_INCLUDED_URL_PARAMS: 'error getting included in url params',
    EVENTS_MAP_ID_URL_PARAMS: 'error getting map id in url params',
    EVENTS_MAX_TICKETS_PER_BOOKING:
      'max tickets per booking must be between 1 and 10',
    EVENTS_PREFERENCE_FEE_BREAKDOWN_VALIDATION:
      "fee break down can not be null or different to 't' or 'f'",
    EVENTS_PROMOTION_BUNDLE_RULE_COMPARITION_VALIDATION:
      'bundle rule first character, must be greater than second character',
    EVENTS_PROMOTION_BUNDLE_RULE_FIRST_ELEMENT_VALIDATION:
      'bundle rule first character, must be a number',
    EVENTS_PROMOTION_BUNDLE_RULE_SECOND_ELEMENT_VALIDATION:
      'bundle rule second character, must be a number',
    EVENTS_PROMOTION_BUNDLE_RULE_VALIDATION:
      'bundle rule must be fill example: 2x1',
    EVENTS_PROMOTION_CAN_NOT_BE_NULL_OBJECT:
      'promotion can not be a null object',
    EVENTS_PROMOTION_CODE_ALREADY_EXISTS:
      'this promotion code is already in use for this event',
    EVENTS_PROMOTION_EXPIRATION_LIMIT_VALIDATION:
      'expiration limit must be greater than zero',
    EVENTS_PROMOTION_FLAT_RULE_VALIDATION:
      'discount amount must be greater than or equal to zero, example: 1000',
    EVENTS_PROMOTION_ID_URL_PARAMS: 'error getting promotion id in url params',
    EVENTS_PROMOTION_PERCENTAGE_RULE_VALIDATION:
      'discount amount must be between 1 to 100, example: 20',
    EVENTS_PROMOTION_TICKET_TYPE_VALIDATION:
      'ticket type does not exist, please assign a ticket type',
    EVENTS_PROMOTION_USED:
      'promotion can not be deleted because is already used',
    EVENTS_PROMOTION_WAS_NOT_CREATED: 'promotion was not created',
    EVENTS_QUERY_PROCESS: 'query error',
    EVENTS_REQUEST_VALIDATION: 'validation error',
    EVENTS_SPONSOR_ID_URL_PARAMS: 'error getting sponsor id in url params',
    EVENTS_STATEMENT_PROCESS: 'statement error',
    EVENTS_SUBDOMAIN_EXIST: 'subdomain already exist',
    EVENTS_SUBDOMAIN_URL_PARAMS: 'error getting subdomain in url params',
    EVENTS_TICKETS_URL_PARAMS: 'error getting tickets in url params',
    EVENTS_TICKET_ID_URL_PARAMS: 'error getting ticket id in url params',
    EVENTS_UPLOADING_IMAGE: 'an error occurred while uploading the image',
    EVENTS_USER_DOES_NOT_EXIST: 'this user does not exist',
    EVENTS_USER_ID_CAN_NOT_BE_NULL_OR_ZERO: 'user id can not be null or zero',
    EVENTS_USER_ID_HEADER: 'error getting user id in headers',
    EVENTS_USE_MAP_URL_PARAMS: 'error getting use map in url params',
    EVENTS_VENUE_VALIDATION: 'venue location can not be string empty or null',
    FORMS_DEFAULT_ERROR: 'an error occurred',
    FORMS_EVENT_NOT_FOUND: 'event not found',
    FORMS_FIELD_OPTIONS_JSON_ITEMS_REQUIRED: 'options no have json items',
    FORMS_FIELD_OPTIONS_REQUIRED:
      'options field is required if field_type is select',
    FORMS_GET_DATA_ERROR: 'get data error',
    FORMS_GET_EVENT_FROM_API_ERROR: 'get event from api error',
    FORMS_LIST_TICKET_TYPE_ERROR: 'list ticket types error',
    FORMS_MAXIMUN_NUMBER_QUESTIONS_REACHED:
      'the maximum number of questions has been reached',
    FORMS_PREFERENCE_NOT_FOUND: 'preference not found',
    FORMS_REQUEST_DATA_INVALID: 'invalid request data',
    FORMS_SOME_OPTION_VALUE_EMPTY:
      'some option_value on options field is empty',
    FORMS_SOME_TICKET_NOT_FOUND: 'some ticket not found',
    FORMS_STATEMENT_PROCESS: 'statement error',
    FORMS_URL_PARAM_EVENT_ID_INVALID: 'url param event id invalid',
    PAYMENTS_ALREADY_CREATED: 'this event already has payment methods',
    PAYMENTS_BODY_VALIDATION: 'object not received to do the action',
    PAYMENTS_DO_NOT_EXIST: 'do not exist payment methods',
    PAYMENTS_DO_NOT_EXIST_BY_DEFAULT: 'do not exist default payment methods',
    PAYMENTS_EVENT_IDS_URL_PARAMS: 'error getting event ids in url params',
    PAYMENTS_EVENT_ID_HEADER: 'error getting event id in header',
    PAYMENTS_EVENT_ID_URL_PARAMS: 'error getting event id in url params',
    PAYMENTS_QUERY_PROCESS: 'query error',
    PAYMENTS_REQUEST_VALIDATION: 'validation error',
    PAYMENTS_STATEMENT_PROCESS: 'statement error',
    PAYMENTS_TICKET_TYPE_NOT_FOUND: 'payment method not found',
    PAYMENTS_USER_NOT_FOUND: 'logged user not found',
    PAYMENTS_WAS_NOT_CREATED: 'payment method was not created',
    REPORTER_BOOKING_NOT_FOUND: 'bookings not found',
    REPORTER_CUSTOM_CLIENT_EMAIL_EMPTY: 'client email is empty',
    REPORTER_DEFAULT_ERROR: 'an error occurred',
    REPORTER_EVENT_NOT_FOUND: 'event not found',
    REPORTER_GENERATE_PDF_FILE_ERROR: 'generate pdf file error',
    REPORTER_GENERATE_XLSX_FILE_ERROR: 'generate xlsx file error',
    REPORTER_GET_BOOKING_FROM_API_NOT_FOUND: 'get booking from api not found',
    REPORTER_GET_DATA_ERROR: 'get data error',
    REPORTER_GET_TICKET_TYPES_FROM_API_ERROR: 'get ticket types from api error',
    REPORTER_QUERY_STRING_INVALID: 'some value on query string invalid',
    REPORTER_REQUEST_DATA_INVALID: 'invalid request data',
    REPORTER_REQUEST_HEADER_INVALID: 'some header on request invalid',
    REPORTER_SAVE_FILE_ERROR: 'save file error',
    REPORTER_SEND_EMAIL_ERROR: 'send email error',
    REPORTER_STATEMENT_PROCESS: 'statement error',
    REPORTER_URL_PARAM_BOOKING_ID_INVALID: 'url param booking id invalid',
    REPORTER_URL_PARAM_EVENT_ID_INVALID: 'url param event id invalid',
    REPORTER_URL_PARAM_EVENT_OR_BOOKING_ID_INVALID:
      'url param event or booking id invalid',
    REPORTER_USER_NOT_FOUND: 'user not found',
    RESERVATION_CREATING_ERR:
      'the selected ticket no longer has availability to generate a courtesy, please try with a different one',
    SEATS_CREATE_EVENT_ON_SEATS_IO_API_ERROR:
      'create event on seats.io api error',
    SEATS_DEFAULT_ERROR: 'an error occurred',
    SEATS_GET_SEAT_EVENT_KEY_FROM_EVENT_API_ERROR:
      'get seat event key from micresrvice event api error',
    SEATS_HEADER_USE_ID_INVALID: 'user id on header invalid',
    SEATS_IDENTIFIER_NOT_FOUND_SEAT_CHANGE_STATUS_ON_SEATS_IO_API_ERROR:
      'identifier not found for seat change status on seats.io api error',
    SEATS_MAP_NOT_FOUND: 'map not found',
    SEATS_MAP_OR_EVENT_NOT_FOUND: 'map or event not found',
    SEATS_REMOVE_EVENT_ON_SEATS_IO_API_ERROR:
      'remove event on seats.io api error',
    SEATS_REQUEST_DATA_INVALID: 'invalid request data',
    SEATS_RESPONSE_CREATE_EVENT_KEY_ON_SEATS_IO_API_IS_EMPTY:
      'response for create event on seats.io is empty',
    SEATS_RESULT_IS_EMPTY: 'result is empty',
    SEATS_SEAT_BOOKED_ON_SEATS_IO_API_ERROR:
      'set seat booked on seats.io api error',
    SEATS_SEAT_CHANGE_STATUS_ON_SEATS_IO_API_ERROR:
      'seat change status on seats.io api error',
    SEATS_STATEMENT_PROCESS: 'statement error',
    SEATS_URL_PARAM_EVENT_ID_INVALID: 'error getting event id on url params',
    SEATS_URL_PARAM_HOLD_ID_INVALID: 'hold id on url param invalid',
    SEATS_URL_PARAM_MAP_ID_INVALID: 'map id on url param invalid',
    SEATS_URL_QUERY_STR_HOLDS_ID_INVALID: 'holds id on query string invalid',
    TICKET_TYPES_ASSOCIATED_FORM: 'ticket has a form associated',
    TICKET_TYPES_BODY_VALIDATION: 'validation error',
    TICKET_TYPES_DATA_NULL_PROCESS: 'information about it does not fount error',
    TICKET_TYPES_EVENT_ID_URL_PARAMS: 'getting event id in url params error',
    TICKET_TYPES_HOLD_ID_URL_PARAMS: 'getting hold id in url params error',
    TICKET_TYPES_ID_URL_PARAMS: 'getting ticket type id in url params error',
    TICKET_TYPES_NAME_ALREADY_EXIST:
      'name for ticket already exist, please assign other error',
    TICKET_TYPES_QUERY_PROCESS: 'query error',
    TICKET_TYPES_REQUEST_VALIDATION: 'ticket has an associated promotion',
    TICKET_TYPES_STATEMENT_PROCESS: 'statement error',
    TICKET_TYPES_USED_IN_RESERVATIONS: 'ticket type used in reservations error',
    TICKET_TYPES_WRONG_STATUS: 'status in url params error',
    TICKET_TYPES_ZERO_EVENT_ID:
      'impossible query with event id  in zero value error',
    USERS_BODY_VALIDATION: 'object not received to do the action',
    USERS_BUCKET_WITHOUT_INITIATING: 'manager empty error',
    USERS_CAN_NOT_ADD_YOURSELF_TO_YOUR_OWN_TEAM:
      'you can not add yourself to your own team',
    USERS_COGNITO_ALIAS_EXISTS: 'this email or phone number al ready exists',
    USERS_COGNITO_CODE_DELIVERY_FAILURE:
      'verification code fails to deliver successfully',
    USERS_COGNITO_CODE_MISMATCH:
      "the provided code doesn't match what the server was expecting",
    USERS_COGNITO_CONCURRENT_MODIFICATION:
      'two or more modifications are happening concurrently',
    USERS_COGNITO_DUPLICATE_PROVIDER: 'the provider is already supported',
    USERS_COGNITO_ENABLE_SOFTWARE_TOKEN:
      'there is a code mismatch and the service fails to configure the software token TOTP multi-factor authentication (MFA)',
    USERS_COGNITO_EXPIRED_CODE: 'code has expired',
    USERS_COGNITO_GROUP_EXISTS: 'group already exists',
    USERS_COGNITO_INTERNAL_ERROR: 'internal error',
    USERS_COGNITO_INVALID_EMAIL_ROLE_ACCESS_POLICY:
      "isn't allowed to use your email identity",
    USERS_COGNITO_INVALID_LAMBDA_RESPONSE_EXCEPTION: 'invalid Lambda response',
    USERS_COGNITO_INVALID_OAUTH_FLOW: 'the specified OAuth flow is not valid',
    USERS_COGNITO_INVALID_PARAMETER:
      'one or more of the parameters are invalid',
    USERS_COGNITO_INVALID_PASSWORD: 'the password is invalid',
    USERS_COGNITO_INVALID_SMS_ROLE_ACCESS_POLICY:
      "the role provided for SMS configuration doesn't have permission to publish using Amazon SNS",
    USERS_COGNITO_INVALID_SMS_ROLE_TRUST_RELATIONSHIP:
      'the trust relationship is not valid for the role provided for SMS configuration',
    USERS_COGNITO_LIMIT_EXCEED:
      'a user exceeds the limit for a requested Amazon Web Services resource',
    USERS_COGNITO_MFA_METHOD_NOT_FOUND:
      "can't find a multi-factor authentication (MFA) method",
    USERS_COGNITO_NOT_AUTHORIZED: 'the email or password is incorrect',
    USERS_COGNITO_PASSWORD_RESET_REQUIRED: 'a password reset is required',
    USERS_COGNITO_PRECONDITION_NOT_MET: 'precondition is not met',
    USERS_COGNITO_RESOURCE_NOT_FOUND: "can't find the requested resource",
    USERS_COGNITO_SCOPE_DOES_NOT_EXIST: "the specified scope doesn't exist",
    USERS_COGNITO_SOFTWARE_TOKEN_MFA_NOT_FOUND:
      "the software token time-based one-time password (TOTP) multi-factor authentication (MFA) isn't activated for the user pool",
    USERS_COGNITO_TOO_MANY_FAILED_ATTEMPTS:
      'the user has made too many failed attempts for a given action',
    USERS_COGNITO_TOO_MANY_REQUEST:
      'the user has made too many requests for a given operation',
    USERS_COGNITO_UNAUTHORIZED: "the request isn't authorized",
    USERS_COGNITO_UNEXPECTED_LAMBDA: 'unexpected exception with Lambda',
    USERS_COGNITO_UNSUPPORTED_IDENTITY_PROVIDER:
      "the specified identifier isn't supported",
    USERS_COGNITO_UNSUPPORTED_OPERATION:
      "you attempt to perform an operation that isn't enabled for the user pool client",
    USERS_COGNITO_UNSUPPORTED_TOKEN_TYPE:
      'unsupported token is passed to an operation',
    USERS_COGNITO_UNSUPPORTED_USER_STATE: 'the user is in an unsupported state',
    USERS_COGNITO_USERNAME_EXISTS: 'This username already exists',
    USERS_COGNITO_USER_IMPORT_IN_PROGRESS:
      "you're trying to modify a user pool while a user import job is in progress for that pool",
    USERS_COGNITO_USER_LAMBDA_VALIDATION:
      'user validation exception with the Lambda service',
    USERS_COGNITO_USER_NOT_CONFIRMED:
      "the user hasn't been successfully confirmed",
    USERS_COGNITO_USER_NOT_FOUND: "user isn't found",
    USERS_COGNITO_USER_POOL_ADD_ON_NOT_ENABLED:
      "user pool add-ons aren't enabled",
    USERS_COGNITO_USER_POOL_CONFIGURATION:
      'user pool configuration is not valid',
    USERS_COGNITO_USER_POOL_TAGGING: "user pool tag can't be set or updated",
    USERS_COLLABORATOR_DUPLICATED: 'this collaborator is already in your team',
    USERS_DATA_NULL_PROCESS: 'information about it does not fount error',
    USERS_DELETING_IMAGE: 'an error occurred while deleting the image',
    USERS_DOES_NOT_EXIST: 'getting user but user does not exist error',
    USERS_FILTER_IS_EMPTY: 'getting filter in query error',
    USERS_HEADER_USER_ID_INVALID: 'user id on header is invalid',
    USERS_PASSWORD_EMPTY: 'can not generate a hash password without password',
    USERS_QUERY_PROCESS: 'query error',
    USERS_REQUEST_VALIDATION: 'validation error',
    USERS_ROLE_EMPTY: 'you must assign a role',
    USERS_SENDING_EMAIL: 'an error occurred while sending the email',
    USERS_STATEMENT_PROCESS: 'statement error',
    USERS_TRANSACTION_PROCESS: 'transaction error',
    USERS_UPLOADING_IMAGE: 'an error occurred while uploading the image',
    USERS_USER_EMAIL_URL_PARAMS: 'getting email in url params error',
    USERS_USER_ID_URL_PARAMS: 'error getting user id in url params',
    USERS_ZIP_CODE_URL_PARAMS: 'error getting zip code in url params',
    holds: {
      INVALID_PAYMENT_METHOD_TYPE: 'Debe configurar un método de pago en efectivo para el evento',
      HOLD_NOT_DOWNLOADABLE: 'No se puede descargar, el hold será utilizado para impresión',
      HOLD_MAX_CAPACITY: 'No se pueden crear holds, ya que supera el aforo máximo.',
      TICKET_QTY_CANNOT_BE_LESS_THAN_AVAILABLE: 'Cannot create the ticket, as the quantity of tickets is less than the available'
    }
  },
  incodeMessage: {
    title: 'For your security',
    subtitle: 'we will verify your identity!',
    footerMessage: 'Send <strong>clear photos</strong> of your <strong>document</strong> and <strong>face</strong> to <strong>verify your identity</strong> securely.',
    bulletPoints: {
      first: 'Have your <strong>identity documents</strong> <strong>at hand</strong> and place them on a firm surface without distracting elements',
      second: '<strong>Remove</strong> caps, glasses and obstructions from your face and smile with a natural expression.',
      third: '<strong>Mind your lighting.</strong> Preferably use natural light to avoid shadows or reflections.',
      fourth: 'Capture your document and face <strong>without cropping, blurring or glare</strong> that alter the photo.',
    },
  },
};

export default en;